class EventEmitter {

    constructor()
    {
        this.events = {};
    }

    _getEventListByName(eventName)
    {
        if ( typeof this.events[eventName] === 'undefined' )
        {
            this.events[eventName] = new Set();
        }
        return this.events[eventName]
    }

    on(eventName, fn)
    {
        this._getEventListByName(eventName).add(fn);
    }

    once(eventName, fn)
    {

        const self = this;

        const onceFn = function (...args) {
            self.removeListener(eventName, onceFn);
            fn.apply(self, args);
        };
        this.on(eventName, onceFn);

    }

    emit(eventName, ...args)
    {

        this._getEventListByName(eventName).forEach(function (fn) {

            fn.apply(this, args);

        }.bind(this));

    }

    removeListener(eventName, fn)
    {
        this._getEventListByName(eventName).delete(fn);
    }
}

class CoreUtils {

    static generateGUID()
    {
        function S4()
        {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return S4() + S4();
    }

    static EventEmitter = EventEmitter;

    static hasPermission(authArr, userRole)
    {
        /**
         * If auth array is not defined
         * Pass and allow
         */
        if ( authArr === null || authArr === undefined )
        {
            // console.info("auth is null || undefined:", authArr);
            return true;
        }
        /**
         * if auth array is empty means,
         * allow only user role is guest (null or empty[])
         */
        else if ( authArr.length === 0 )
        {
            // console.info("auth is empty[]:", authArr);
            return !userRole || userRole.length === 0;
        }
        /**
         * Check if user has grants
         */
        else
        {
            // console.info("auth arr:", authArr);
            /*
            Check if user role is array,
            */
            if ( userRole && Array.isArray(userRole) )
            {
                return authArr.some(r => userRole.indexOf(r) >= 0);
            }

            /*
            Check if user role is string,
            */
            return authArr.includes(userRole);
        }
    }

}

export default CoreUtils;
