import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  animatedItem: {
    '& circle:nth-of-type(1)': {
      animation: `$fade 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 0ms infinite
        alternate-reverse`
    },
    '& circle:nth-of-type(2)': {
      animation: `$fade 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 400ms infinite
        alternate-reverse`
    },
    '& circle:nth-of-type(3)': {
      animation: `$fade 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 800ms infinite
        alternate-reverse`
    },
    '& circle:nth-of-type(4)': {
      animation: `$fade 700ms cubic-bezier(0.39, 0.58, 0.57, 1) 1200ms infinite
        alternate-reverse`
    }
  },
  "@keyframes fade": {
    "from": {
      opacity: 1
    },
    "to": {
      opacity: 0.45
    }
  }
}));

export default function TypingIndicator() {
  const classes = useStyles();
  return (
    <svg width="30" viewBox="0 0 15 6" className={classes.animatedItem}>
      <g fill="currentColor">
        <circle cx="3" cy="3" r="1" />
        <circle cx="6" cy="3" r="1" />
        <circle cx="9" cy="3" r="1" />
        <circle cx="12" cy="3" r="1" />
      </g>
    </svg>
  );
}