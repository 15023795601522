import React, {useEffect} from 'react';
import jssExtend from 'jss-extend';
import {create} from 'jss';
import {Router} from 'react-router-dom';
import {StylesProvider, jssPreset, createGenerateClassName} from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { themeConfig, awsConfig } from 'config';
import history from '@history';
import store from 'store';
import {Provider} from 'react-redux';
import routes from 'config/routesConfig';
import AppContext from 'AppContext';
import {CoreMessage, CoreAuthorization} from '@core';
import MainLayout from 'components/Layout/MainLayout';

import {Auth as cogAuth} from '@tycoonlabs/web-auth';
cogAuth.config(awsConfig);
// cogAuth.printConfigs(); //optional

const jss = create({
	...jssPreset(),
	plugins       : [...jssPreset().plugins, jssExtend()],
	insertionPoint: document.getElementById('jss-insertion-point'),
});
const generateClassName = createGenerateClassName();
const App = () => {

	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<ThemeProvider theme={themeConfig}>
						<Router history={history}>
							<MainLayout />
						</Router>
						<CoreMessage />
					</ThemeProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>);
};
export default App;
