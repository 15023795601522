import {combineReducers} from 'redux';
import auth from 'store/reducers/auth';
import core from 'store/reducers/core';
import {Session as session} from '@tycoonlabs/web-auth';

const createReducer = (asyncReducers) =>
	combineReducers({
		auth,
		core,
		session,
		...asyncReducers
	});

export default createReducer;
