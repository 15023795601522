import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, FormControl} from '@material-ui/core';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Input, InputLabel} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch} from 'react-redux';
import * as Actions from 'store/actions/core';

import axios from 'services/axiosService';
import {Auth} from '@tycoonlabs/web-auth'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
	inputField: {
			background: '#f5f5f5',
			borderRadius: 10,
			height: 40,
			paddingLeft: 10,
			marginBottom: 15,
			width: 300
	},
	textAreaField: {
			background: '#f5f5f5',
			borderRadius: 10,
			paddingLeft: 10,
			width: 300
	}
}));

export default function SMSDialog(props) {
  const classes = useStyles(props);
  const {open, handleClose, handleSuccess} = props;
	const [message, setMessage] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const dispatch = useDispatch();
  let {customer} = props;
	if (!customer) customer = {};
  const token = Auth.getToken();
  
	useEffect(() => {
    if (customer && customer.phone)
      setPhoneNumber(customer.phone);
    
	}, [customer]);
	const sendSMS = () => {
    const data = {phoneNumber, message};
		axios.post('/api/chat/sendSMS', data, { headers: {"Authorization": "Bearer " + token}})
			.then(({data}) => {
        console.log(data);
        handleSuccess(message);
				dispatch(Actions.showMessage({message: 'Send SMS succeed!', variant: 'success'}));
				handleClose();
			}).catch(() => {
				dispatch(Actions.showMessage({message: 'Send SMS Failed!', variant: 'error'}));
			});
	};
	return (
    <Dialog open={open}>
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h5">Send SMS</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl className="flex">
          <InputLabel shrink htmlFor="phonenumber-input" className="mb-10">
              Phone Number
          </InputLabel>
          <Input
            classes={{
                root: classes.inputField,
            }}
            autoFocus
            id="phonenumber-input"
            disableUnderline
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
        </FormControl>
        <FormControl className="flex">
          <InputLabel shrink htmlFor="message-input" className="mb-10">
              Message
          </InputLabel>
          <Input
            classes={{
                root: classes.textAreaField,
            }}
            value={message}
            id="message-input"
            rows="4"
            disableUnderline
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            multiline
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={sendSMS} color="primary" variant="contained" className="rounded-full" size="large">
          Send
        </Button>
        <Button onClick={handleClose} className="rounded-full" size="large">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
	);
}
