import React, {useRef, useEffect, useState} from 'react';
import {Avatar, Paper, Typography, TextField, IconButton, Icon} from '@material-ui/core';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment/moment';
import {makeStyles} from '@material-ui/styles';
import imgAvatar from 'assets/avatar.svg';
import {CoreScrollbars} from '@core';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import SMSDialog from './SMSDialog';
import {ReactComponent as SendIcon} from 'assets/send.svg';
import useWindowDimensions from '../Layout/Shared/WindowSize';

const useStyles = makeStyles(theme => ({
	messageRow: {
		maxWidth: "667px",
		'&.contact'                       : {
			'& .bubble'       : {
				maxWidth: "438px",
				minWidth: "438px",
				backgroundColor        : theme.palette.primary.main,
				color                  : theme.palette.primary.contrastText,
				borderTopLeftRadius    : 0,
				borderBottomLeftRadius : 0,
				borderTopRightRadius   : 10,
				borderBottomRightRadius: 10,
				'& .time'              : {
					marginLeft: 12
				}
			},
			'&.first-of-group': {
				'& .bubble': {
					borderTopLeftRadius: 10
				}
			},
			'&.last-of-group' : {
				'& .bubble': {
					borderBottomLeftRadius: 10
				}
			}
		},
		'&.me'                            : {
			paddingLeft: 40,

			'& .avatar'       : {
				order : 2,
				margin: '0 0 0 16px'
			},
			'& .bubble'       : {
				maxWidth: "440px",
				minWidth: "440px",
				marginLeft             : 'auto',
				color: '#999999',
    		border: 'solid 1px #d4d4d4',
    		backgroundColor: '#ffffff',
				borderTopLeftRadius    : 10,
				borderBottomLeftRadius : 10,
				borderTopRightRadius   : 0,
				borderBottomRightRadius: 0,
				'& .time'              : {
					justifyContent: 'flex-end',
					right         : 0,
					marginRight   : 12
				}
			},
			'&.first-of-group': {
				'& .bubble': {
					borderTopRightRadius: 10
				}
			},

			'&.last-of-group': {
				'& .bubble': {
					borderBottomRightRadius: 10
				}
			}
		},
		'&.contact + .me, &.me + .contact': {
			paddingTop: 20,
			marginTop : 20
		},
		'&.first-of-group'                : {
			'& .bubble': {
				borderTopLeftRadius: 10,
				paddingTop         : 17
			}
		},
		'&.last-of-group'                 : {
			'& .bubble': {
				borderBottomLeftRadius: 10,
				paddingBottom         : 17,
				'& .time'             : {
					display: 'flex'
				}
			}
		}
	},
	inputRoot: {
		background: theme.palette.grey[100],
		height: 50
	},
	generalPadding:{
		padding: "0 30px",
		'@media (min-width: 1280px)':{
			padding: "0 60px"
		}
	}
}));

function MessageBox(props)
{
	const classes = useStyles(props);
	const chatRef = useRef(null);
	const adminIdentity = useSelector(({auth}) => auth.user.chatIdentity);
	const { width, height } = useWindowDimensions();

	const { channel, customer } = props;
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState('');
	const [open, setOpen] = useState(false);
	const currentMessages = useRef();
	currentMessages.current = messages;

	useEffect(() => {	
		if (!channel || !channel.getMessages) return;

		const messagesLoaded = (messagePage) => {
			setMessages(messagePage.items.map(twilioMessageToChatMessage));
			scrollToBottom();
		};
	
		const messageAdded = (message) => {
			setMessages([
				...currentMessages.current,
				twilioMessageToChatMessage(message, currentMessages.current.length),
			]);
			scrollToBottom();
		};

		const initChannel = () => {
			channel.getMessages().then(messagesLoaded);
			channel.on('messageAdded', messageAdded);
		};
		initChannel();
		return () => {
			channel.removeListener('messageAdded', messageAdded);
		};
	}, [channel]);

	const twilioMessageToChatMessage = (message, id) => {
		return {
			text: message.body,
			type: 'text',
			id: id + '' || '0',
			author: { id: message.author, name: message.author },
			senderId: message.author,
			username: message.author,
			avatar: { name: message.author },
		};
	};

	function scrollToBottom()
	{
		chatRef.current.scrollTop = chatRef.current.scrollHeight;
	}

	function shouldShowContactAvatar(item, i)
	{
		return (
			item.author.id !== adminIdentity &&
            ((messages[i + 1] && messages[i + 1].author.id === adminIdentity) || !messages[i + 1])
		);
	}

	function isFirstMessageOfGroup(item, i)
	{
		return (i === 0 || (messages[i - 1] && messages[i - 1].author.id !== item.author.id));
	}

	function isLastMessageOfGroup(item, i)
	{
		return (i === messages.length - 1 || (messages[i + 1] && messages[i + 1].author.id !== item.author.id));
	}
	const handleOnSubmit = (e) => {
		e.preventDefault();
		channel.sendMessage(newMessage);
		setNewMessage('');
	};
	const handleSMSCheckClick = () => {
		setOpen(true);
	}
	const handleSMSDialogClose = () => {
		setOpen(false);
	}

	return (
		<div className={clsx('flex flex-col relative ', props.className)} style={{minWidth: width > 1280 ? "600px" : "400px"}}>

			<CoreScrollbars
				ref={chatRef}
				className="flex flex-col-sc"
				style={{overflowY:"auto"}}
			>
				{messages && messages.length > 0 ?
					(
						<div className={clsx(classes.generalPadding, " pt-16 pb-40 items-center ")} style={{minWidth: width > 1280 ? "600px" : "400px"}}>
							{messages.map((item, i) => {
								// const contact = item.who === user.id ? user : contacts.find(_contact => _contact.id === item.who);
								// console.log(item)
								return (
									<div
										key={i}
										className={clsx(
											classes.messageRow,
											' flex-row flex-grow-0 flex-shrink-0 items-start justify-end relative pr-16 pb-4 pl-16',
											{'me': item.author.id === adminIdentity},
											{'contact': item.author.id !== adminIdentity},
											{'first-of-group': isFirstMessageOfGroup(item, i)},
											{'last-of-group': isLastMessageOfGroup(item, i)},
											(i + 1) === messages.length && 'pb-96'
										)}
									>
										{shouldShowContactAvatar(item, i) && (
											<Avatar className="avatar absolute left-0 m-0 -ml-32 w-35 h-35" src={imgAvatar}/>
										)}
										<div className="bubble flex relative items-center p-12 max-w-full"
												 style={{borderRadius : item.author.id === adminIdentity ? '10px 10px 0 10px' : '0 10px 10px 10px'}}
											>
											<div className="leading-tight whitespace-pre-wrap text-16 px-16">{item.text}</div>
											<Typography
												className={
													clsx('time absolute hidden w-full text-12 mt-8 -mb-24 left-0 bottom-0 whitespace-no-wrap'

												)}
												style={{color:"#cecece", padding:"8px;", justifyContent : item.author.id === adminIdentity ? 'flex-start' : 'flex-end'}}>
												{moment(item.time).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<div className="flex flex-row-sc px-16 pb-24 ">
							<div className=" px-24 ">
								<Icon className="text-24" color="disabled">chat</Icon>
							</div>
							<Typography className="text-center text-18" color="textSecondary">
                               Select a conversation to start messaging.
							</Typography>
						</div>
					)
				}			
			</CoreScrollbars>
			{messages && messages.length > 0 && (
			<form className="absolute bottom-0 right-0 left-0 py-24 px-8 flex-col items-center" onSubmit={handleOnSubmit}>
				<Paper className="flex flex-col" elevation={0} style={{minWidth: width > 1280 ? "500px" : "400px", maxWidth: "667px"}}>
					<div className="flex">
					<FormControlLabel
						control={<Checkbox  name="Send via SMS" onClick={handleSMSCheckClick}/>}
						label={<Typography className={"text-12 font-bold"}>Send via SMS</Typography>}
					/></div>
					<div className="flex items-center relative bg-transparent">
					<TextField
						autoFocus={false}
						id="message-input"
						value={newMessage}
						onChange={event => {
							setNewMessage(event.target.value);
						//	channel.current.typing();}
						}}
						className="flex-1"
						classes = {{
							root: clsx(classes.inputRoot, 'flex border border-1 border-solid rounded-6 border-grey-100')
						}
						}
						InputProps={{
							disableUnderline: true,
							classes         : {
								root : 'flex flex-grow flex-shrink-0 ml-16 mr-48 text-16 rounded-4',
								input: ''
							},
							placeholder     : 'Type here...'
						}}
						InputLabelProps={{
							shrink   : false,
							className: classes.bootstrapFormLabel
						}}
					/>
					<IconButton type="submit">
						<SendIcon style={{width:"30px"}} />
					</IconButton></div>
				</Paper>
			</form>
				)}
			<SMSDialog
				open={open}
				handleClose={handleSMSDialogClose}
				customer={customer}
				handleSuccess={(msg) => channel.sendMessage("From SMS: " + msg)}
			/>
		</div>
	);
}

MessageBox.propTypes = {
	className: PropTypes.string
};
export default MessageBox;
