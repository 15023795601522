import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Container, Typography} from '@material-ui/core';
import MessageBox from 'components/Chat/MessageBox';
import RightPanel from 'components/Chat/RightPanel';
import LeftPanel from 'components/Chat/LeftPanel';
import TopBar from 'components/Chat/TopBar';
import Chat from 'twilio-chat';
import {useSelector} from 'react-redux';
import {Auth} from '@tycoonlabs/web-auth'
import {
	PageHeader,
	PrimaryButton,
	FullPage,
} from '@tycoonlabs/web-components'

import axios from 'services/axiosService';

const useStyles = makeStyles(theme => ({
	contentCard: {
		display: 'flex',
		position: 'relative',
		flex: '1 1 100%',
		flexDirection: 'row',
		border: '1px solid',
		borderColor: '#d4d4d4',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden',
		marginBottom: "50px",
	},
	content: {
		display: 'flex',
		flex: '1 1 100%',
		minHeight: 0
	}
}));

export default function ChatApp(props) {
	const classes = useStyles(props);
	const client = useRef(null);
	const [channels, setChannels] = useState([]);
	const [currentChannel, setCurrentChannel] = useState({});
	const [currentCustomer, setCurrentCustomer] = useState(null);
	const identity = useSelector(({auth}) => auth.user.chatIdentity);

	const channelsRef = useRef(channels);
	channelsRef.current = channels;

	let _isMounted = false;
	const token = Auth.getToken();
	useEffect(() => {
		_isMounted = true;
		axios.post('/api/chat/token', {identity}, {headers: {"Authorization": "Bearer " + token}})
			.then(({data}) => {
				let clientOptions = {}; //{ logLevel: 'debug' };
				return Chat.create(data.token, clientOptions);
			})
			.then(setupChatClient)
			.catch(handleError);
		return () => {
			_isMounted = false;
			if (client && client.current) {
				client.current.removeAllListeners('channelAdded');
				client.current.removeAllListeners('channelRemoved');
			}
		}
	}, [])

	const handleError = (error) => {
		console.error('couldnot load', error);
	};
	// const loadChannels = () => {
	// 	client.current.getSubscribedChannels({criteria: 'lastMessage', order: 'ascending'}).then((channels) => {
	// 		if (_isMounted)	setChannels(channels.items);
	// 	});
	// };
	const addChannel = (channel) => {
		if (_isMounted) setChannels([...channelsRef.current, channel]);
	}

	const setupChatClient = (newClient) => {
		client.current = newClient;
		// loadChannels();
		client.current.on('tokenAboutToExpire', updateToken);
		client.current.removeAllListeners('channelAdded');
		client.current.removeAllListeners('channelRemoved');
		client.current.on('channelAdded', addChannel);
	};

	const handleClickChannel = (channel) => {
		// channel.getChannel().then(async (channel) => {
		// 	if(channel.channelState.status !== 'joined') {
		// 		alert('joining');
		// 		await channel.join();
		// 	}
		// 	console.log(channel);
		// 	setCurrentChannel(channel);
		// 	setCurrentCustomer(channel.attributes);
		// }

		setCurrentChannel(channel);
		setCurrentCustomer(channel.attributes);

		// );
	};

	const updateToken = () => {
		axios.post('/api/chat/token', {
			identity
		}).then(({data}) => {
			client.current.updateToken(data.token);
		})
	};

	return (
		<>
			{/*<TopBar />*/}
			<PageHeader>
				<h1>Messaging</h1>
				{/*<PrimaryButton size="medium" color="secondary">*/}
				{/*	Send Message*/}
				{/*</PrimaryButton>*/}
			</PageHeader>
			<FullPage className={"flex"}>
				<div className={classes.contentCard}>
					<LeftPanel channels={channels} handleClickChannel={handleClickChannel} currentChannel={currentChannel}/>
					<main className={classes.content}>
						<Container width="1400" disableGutters>
							<MessageBox className="flex flex-1 z-10 h-full" channel={currentChannel} customer={currentCustomer}/>
						</Container>
					</main>
					<RightPanel customer={currentCustomer}/>
				</div>
			</FullPage>
		</>
	);
}
