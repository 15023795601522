import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Avatar, ListItemText, ListItem} from '@material-ui/core';
import imgAvatar from 'assets/avatar.svg';
import TypingIndicator from './TypingIndicator';


const useStyles = makeStyles(theme => ({
	contactListItem: {
		'&.active'  : {
			backgroundColor: theme.palette.background.paper
		},
		'&.hover'  : {
			backgroundColor: theme.palette.background.paper
		}
	},
	status       : {
		width       : 12,
		height      : 12,
		marginTop		: -4,
		border      : '2px solid ' + theme.palette.background.default,
		backgroundColor: '#62ed75',
		borderRadius: '50%',
		zIndex      : 10,
		'&.inactive': {
			backgroundColor: '#dddddd',
		}
	},
	borderLine: {
		background: '#f2f2f2',
    height: 1,
    marginTop: -1,
    marginBottom: -1
	}
}));

export default function LeftPanelItem(props) {
	const classes = useStyles(props);
	const { channelData, selected } = props;
	const [isTyping, setIsTyping] = useState(false);

	let { attributes } = props.channelData;
	if (!attributes) attributes = {};
	const [lastMessage, setLastMessage] = useState({});
	let _isMounted = true;
	useEffect(() => {
		channelData.getMessages().then(messages => {
			if (_isMounted && messages.items.length) setLastMessage(messages.items[messages.items.length - 1]);
		})
		channelData.on('messageAdded', messageUpdated)
		channelData.on('typingStarted', () => {
			setIsTyping(true);
			console.log('Typing started');
		});
		channelData.on('typingEnded', () => {
			setIsTyping(false);
			console.log('Typing ended');
		})
		return () => {
			_isMounted = false;
			if (channelData) {
				channelData.removeAllListeners('messageAdded');
			}
		}
	}, [channelData])

	const messageUpdated = message => {
		if (_isMounted) setLastMessage(message);
	}

	return (
		<div className="flex flex-col">
			<ListItem
				button
				className={clsx(classes.contactListItem, 'px-28 py-10 min-h-40')}
				onClick={() => {props.onChannelClick(props.channelData)}}
				selected={selected}
			>
				<div className="relative mr-4">
													
					<Avatar src={imgAvatar} alt='Lisa Smith' className="w-60 h-60">
									Alis
					</Avatar>
				</div>
				<ListItemText
					classes={{
						root     : 'min-w-px',
						secondary: 'truncate'
					}}
					className={" px-12"}
					primary={
						<div className="flex items-center ">
							<span className="mr-2 text-center text-14 pr-8 font-bold " style={{whiteSpace: "nowrap"}}>{attributes.name || ''}</span>
							<div className={clsx(classes.status, props.status)}/>
						</div>
					}
					secondary={<span className={"text-12 font-bold"} style={{color: "#CECECE"}}>
						{isTyping ? <TypingIndicator /> : lastMessage.body}
					</span>}
				/>
				
				<div className="flex flex-col justify-center items-end">
					<Typography className="whitespace-no-wrap mb-8 text-12" style={{color: "#CECECE"}}>
						{moment(lastMessage.timestamp).format('h:m A, M/D/Y')}
					</Typography>
				</div>
			</ListItem>
			<div className={clsx("ml-72 mr-28", classes.borderLine)}></div>
		</div>
	);
};

LeftPanelItem.defaultProps = {
	onChannelClick: () => {}
}