import React , {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, Toolbar, Typography, Icon, IconButton, Input, Paper} from '@material-ui/core';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import LeftPanelItem from 'components/Chat/LeftPanelItem';
import {CoreScrollbars} from '@core';
import moment from 'moment';
import useWindowDimensions from '../Layout/Shared/WindowSize';


const getClasses = (drawerWidth, theme) => {
	const makeClasses = makeStyles(theme => ({
		drawerPaper: {
			width: drawerWidth,
			maxWidth: '100%',
			overflow: 'hidden',
			height: '100%',
			[theme.breakpoints.up('md')]: {
				position: 'relative'
			}
		},
		contactListItem: {
			'&.active': {
				backgroundColor: theme.palette.background.paper
			}
		},
		unreadBadge: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		listItemPrimary: {
			textAlign: 'center'
		},
		selectRoot: {
			'&:focus': {
				borderRadius: 10
			},
			borderRadius: 10,
			background: theme.palette.grey[100],
			height: 50
		},
		status: {
			width: 12,
			height: 12,
			marginTop: -4,
			border: '2px solid ' + theme.palette.background.default,
			backgroundColor: '#62ed75',
			borderRadius: '50%',
			zIndex: 10,
			'&.inactive': {
				backgroundColor: '#dddddd',
			}
		}
	}))
	return makeClasses(theme);
};

export default function LeftPanel(props) {
	const { width, height } = useWindowDimensions();
	const drawerWidth = width > 1280 ? 480 : 380;
	const classes = getClasses(drawerWidth, props);
	const { channels, currentChannel } = props;
	const [activeChannels, setActiveChannels] = useState([]);
	const [inactiveChannels, setInactiveChannels] = useState([]);

	useEffect(() => {
		const setChannels = () => {
			const _activeChannels = [];
			const _inactiveChannels = [];
			channels.forEach(item => {
				// console.log(moment(item.lastMessage.timestamp).add(20, 'm').isBefore());
				if (item.lastMessage && moment(item.lastMessage.timestamp).add(20, 'm').isAfter())
					_activeChannels.push(item);
				else
					_inactiveChannels.push(item);
			})
			setActiveChannels(_activeChannels);
			setInactiveChannels(_inactiveChannels);
		}
		setChannels();
		const interval = setInterval(setChannels, 5000)
		return () => clearInterval(interval);
	}, [channels])

	return (
	
		<Drawer 
			className="h-full z-20"
			variant="permanent"
			anchor="left"
			classes={{
				paper: clsx(classes.drawerPaper, 'left-0')
			}}
			open>
				
			<Toolbar className=" justify-between items-center pl-28 pr-20 pt-20">
				
				<Paper className={clsx(classes.paper, 'flex p-1 items-center w-full px-4 rounded-full')} variant="outlined">

					<Icon className="mr-2 ml-6" color="action" style={{fontSize:"24px"}}>search</Icon>

					<Input
						placeholder="Search or start new chat"
						className='flex flex-1 text-14'
						disableUnderline
						fullWidth
						inputProps={{
							'aria-label': 'Search',
						}}
					/>
				</Paper>
				<IconButton
					aria-haspopup="true"
				>
					<TuneOutlinedIcon style={{fontSize: "32px", color:"#b6b6b6"}}/>
				</IconButton>
			</Toolbar>

			<CoreScrollbars
				className="flex flex-1 flex-col overflow-y-auto"
			>
				<div>
					<Typography
						className="font-bold text-14 px-28 py-12"
					>Active Conversations
					</Typography>
					{
						activeChannels.map((item) => {
							return <LeftPanelItem
												key={item.sid}
												channelData={item}
												onChannelClick={(item) => props.handleClickChannel(item)}
												selected={currentChannel.sid === item.sid}
											/>
						})
					}
					<Typography
						className="font-bold text-14 px-28 py-12"
					>Inactive Conversations
					</Typography>
					{
						inactiveChannels.map((item) => {
							return <LeftPanelItem 
												key={item.sid}
												status="inactive"
												channelData={item}
												onChannelClick={(item) => props.handleClickChannel(item)}
												selected={currentChannel.sid === item.sid}
												/>
						})
					}
				</div>
			</CoreScrollbars>
		</Drawer>
	);
}
