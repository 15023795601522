import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {CoreHighlight} from '@core';
import {useSelector} from 'react-redux';
import PromotionForm from './PromotionForm'
import {urlConfig} from 'config';
import {
	PageHeader,
	PrimaryButton,
	Page,
	FullPage,
} from '@tycoonlabs/web-components'

const useStyles = makeStyles(theme => ({
	contentCard: {
		display: 'flex',
		position: 'relative',
		flex: '1 1 100%',
		flexDirection: 'row',
		border: '1px solid',
		borderColor: '#d4d4d4',
		backgroundColor: theme.palette.background.paper,
		minHeight: 0,
		overflow: 'hidden'
	},
	content: {
		display: 'flex',
		flex: '1 1 100%',
		minHeight: 0,
		flexDirection: 'column'
	}
}));

export default function TrackcodeComponent(props) {
	const classes = useStyles(props);
	const chatIdentity = useSelector(({auth}) => auth.user.chatIdentity);
	const twilioNumber = useSelector(({auth}) => auth.user.twilioNumber);

	return (
		<>
			<PageHeader>
				<h1>Settings</h1>
			</PageHeader>
			<Page>
				{/*<div className={classes.contentCard}>*/}
				{/*	<main className={classes.content}>*/}

						<div className="max-w-2xl">
							<Typography className="mb-16 text-14" component="p">
								You need to copy this code and paste into the bottom of your website, landing page.
							</Typography>
							<CoreHighlight component="pre" className="language-javascript my-16">
								{`
								<!-- Start of BackOffice Embed Code -->
								<script type="text/javascript" id="bo-script-loader" async defer src="${urlConfig.EMBED_URL}js/${chatIdentity}.js"></script>
								<!-- End of BackOffice Embed Code -->
							`}
							</CoreHighlight>
							<Typography className="mb-16 text-24" component="p">
								Your Twilio Number: {twilioNumber}
							</Typography>
						</div>
						<div className="p-24">
							<PromotionForm/>
						</div>
					{/*</main>*/}
				{/*</div>*/}
			</Page>
		</>
	);
}
