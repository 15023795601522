import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Drawer, Toolbar, Typography, Avatar, FormControl, Select, MenuItem, Icon, ListItemText} from '@material-ui/core';
import imgAvatar from 'assets/avatar.svg';
import useWindowDimensions from '../Layout/Shared/WindowSize';

const getClasses = (drawerWidth, theme) => {
	const makeClasses = makeStyles(theme => ({
		drawerPaper: {
			width: drawerWidth,
			maxWidth: '100%',
			overflow: 'hidden',
			height: '100%',
			[theme.breakpoints.up('md')]: {
				position: 'relative'
			},
			"zIndex": "auto"
		},
		listItemPrimary: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: 12,
			height: 12,
			lineHeight: '12px',
			marginBottom: 17
		},
		listItemSecondary: {
			textAlign: 'center',
			fontWeight: '600',
			fontSize: 16,
			height: 16,
			lineHeight: '16px'
		},
		selectRoot: {
			'&:focus': {
				borderRadius: 10
			},
			borderRadius: 10,
			background: theme.palette.grey[100],
			height: 50
		},
		status: {
			width: 12,
			height: 12,
			marginRight: 4,
			border: '2px solid ' + theme.palette.background.default,
			backgroundColor: '#62ed75',
			borderRadius: '50%',
			zIndex: 10,
		},
		rightPanelIcon: {
			minWidth: "51px",
			minHeight: "51px",
			textAlign: "center",
			lineHeight: "54px",
			margin: "20px"
		}
	}));
	return makeClasses(theme);
}

export default function RightPanel(props) {
	const { width, height } = useWindowDimensions();
	const drawerWidth = width > 1280 ? 480 : 280;
	const classes = getClasses(drawerWidth, props);
	let {customer} = props;
	return (
		<Drawer 
			className="h-full"
			variant="permanent"
			anchor="right"
			classes={{
				paper: clsx(classes.drawerPaper)
			}}
			open>
			{customer &&
			<div className="flex flex-col flex-auto h-full">
				<Toolbar className=" flex-col justify-center items-center p-24">
					<Avatar src={imgAvatar} alt="Lisa Smith" className="w-88 h-88">
						{customer.name}
					</Avatar>
					<Typography color="inherit" className="mt-16 font-600 text-18 font-bold">{customer.name}</Typography>
					<div className="flex items-center">
						<div className={clsx(classes.status)}/>
						<Typography color="inherit" className="text-14 font-bold text-gray-300">Online</Typography>
					</div>
				</Toolbar>
				<div className=" flex-row justify-center items-center">
					<div className={clsx(classes.rightPanelIcon, 'flex-col-cc border border-solid rounded-sm border-gray-100 ')}>
						<Icon fontSize="large" >phone_android_outlined</Icon>
					</div>
					<div className={clsx(classes.rightPanelIcon, 'flex-col-cc border border-solid rounded-sm border-gray-100 ')}>
						<Icon fontSize="large">mail_outlined</Icon>
					</div>
					<div className={clsx(classes.rightPanelIcon, 'flex-col-cc border border-solid rounded-sm border-gray-100 ')}>
						<Icon fontSize="large">lock_outlined</Icon>
					</div>
				</div>
				<div className=" flex-col justify-center items-center mt-32">
					<ListItemText
						classes={{
							root     : 'min-w-px mb-24',
							primary: classes.listItemPrimary,
							secondary: classes.listItemSecondary
						}}
						primary='Phone'
						secondary={customer.phone}
					/>
					<ListItemText
						classes={{
							root     : 'min-w-px',
							primary: classes.listItemPrimary,
							secondary: classes.listItemSecondary
						}}
						primary='Email'
						secondary={customer.email || ''}
					/>
				</div>
				{/*<Container className="px-40 mt-40">*/}
				{/*	<Typography variant="subtitle2" className="ml-2 mb-6">Assigned to</Typography>*/}
				{/*	{*/}
				{/*	<FormControl className="w-full mb-16">*/}
				{/*		<Select*/}
				{/*			labelId="demo-customized-select-label"*/}
				{/*			id="demo-customized-select"*/}
				{/*			classes={{root: clsx(classes.selectRoot, 'items-center flex p-0 pl-8')}}*/}
				{/*			disableUnderline*/}
				{/*		>*/}
				{/*			<MenuItem value="0">*/}
				{/*				<em>None</em>*/}
				{/*			</MenuItem>*/}
				{/*			<MenuItem value={10}>Ten</MenuItem>*/}
				{/*			<MenuItem value={20}>Twenty</MenuItem>*/}
				{/*			<MenuItem value={30}>Thirty</MenuItem>*/}
				{/*		</Select>*/}
				{/*	</FormControl>*/}
				{/*	}*/}
				{/*</Container>*/}
			</div>
				}
		</Drawer>
	);
}
