/* eslint-disable react/display-name */
import React from 'react';
import {Redirect} from 'react-router-dom';
import authRoles from 'providers/authRoles';
import {Callback, Auth, PrivateRoute} from '@tycoonlabs/web-auth'

const routes = [
	{
		path     : '/auth',
		exact    : true,
		component: {Callback}
	},
	{
		path     : '/login',
		auth 			: authRoles.onlyGuest,
		component: React.lazy(() => import('components/Auth/LoginPage'))
	},
	{
		path     : '/register',
		auth 			: authRoles.onlyGuest,
		component: React.lazy(() => import('components/Auth/RegisterPage'))
	},
	{
		path     : '/',
		exact    : true,
		auth 			: authRoles.admin,
		component: React.lazy(() => import('components/Chat/ChatApp'))
	},
	{
		path     : '/settings',
		auth 			: authRoles.admin,
		component: React.lazy(() => import('components/Settings/SettingsComponent'))
	},
	{
		component: () => <Redirect to="/" />
	}
];

export default routes;
