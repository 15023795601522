import React, {useContext, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CoreSuspense} from '@core';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom'
import AppContext from 'AppContext';
import {useSelector} from 'react-redux';
import ChatApp from 'components/Chat/ChatApp'
import SettingsComponent from 'components/Settings/SettingsComponent'
import {Callback, Auth, PrivateRoute} from '@tycoonlabs/web-auth'
import {useDispatch} from 'react-redux';
import axios from 'services/axiosService';
import * as userActions from 'store/actions/auth';

import {Img} from "react-image";
import {Layout} from '@tycoonlabs/web-components';

import {ReactComponent as MessagingIcon} from 'assets/messaging.svg';
import {ReactComponent as SettingsIcon} from 'assets/settings.svg';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: theme.spacing(12),
		height: '100%',
		overflow: 'auto'
	},
}));

export default function MainLayout(props) {
	const classes = useStyles(props);
	const appContext = useContext(AppContext);
	const {routes} = appContext;
	const isLoggedIn = useSelector(({session}) => session.isLoggedIn);
	const session = useSelector(({session}) => session);
	const user = useSelector(({auth}) => auth.user);

	const location = useLocation();
	const history = useHistory();
	if (location.pathname === '/') {
		history.push('/chat');
	}

	const appName = "Messaging";
	const companyName = "Back Office";
	const appIcon = <Img src={"//cdn.backoffice.co/media/app/messaging.png"}/>;

	const primaryNavs = useMemo(() => ([
		{
			text: "Messaging",
			icon: <MessagingIcon/>,
			path: "/chat"
		},
		{
			text: "Settings",
			icon: <SettingsIcon/>,
			path: "/settings"
		}
	]), []);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(Auth.signIn());
	}, []);
	const token = Auth.getToken();

	useEffect(() => {
		if (!session || !token) return
		axios.get('/api/auth/access-token', {headers: {"Authorization": "Bearer " + token}})
			.then(({data}) => {
				console.log(data)
				dispatch(userActions.setUserData(data.user))
			})
	}, [session]);

	return (
		isLoggedIn && user.role ? (
			<div className='backoffice flex-row'>
				<Layout appName={appName}
								companyName={companyName}
								appIcon={appIcon}
								primaryNavs={primaryNavs}
								showNav={isLoggedIn}
				>
					<Container maxWidth="xl" className={classes.container} elevation={0}>
						<CoreSuspense delay={1000}>
							<>
								<Switch>
									<Route exact path="/auth" component={Callback}/>
									<Route path='/' exact component={ChatApp}/>
									<Route path='/chat' exact component={ChatApp}/>
									<Route path='/settings' exact component={SettingsComponent}/>
								</Switch>
							</>
						</CoreSuspense>
					</Container>
				</Layout>
			</div>
		) : (
			<CoreSuspense delay={1000}>
				<>
					<Route exact path="/auth" component={Callback}/>
				</>
			</CoreSuspense>
		)
	);
}