import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
const Vaud = {
	fontFamily: '"Vaud", sans-serif'
};
const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#323232',
		},
		secondary: {
			main: green[500],
		},
		background: {
			default: '#fff'
		}
	},
	typography: {
		fontFamily: '"Vaud", sans-serif',
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [Vaud],
			},
		},
	},
});
export default theme;