import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Input, InputLabel, FormControl, Button} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from 'store/actions/core';
import * as userActions from 'store/actions/auth';

import axios from 'services/axiosService';
import {Auth} from '@tycoonlabs/web-auth'

const useStyles = makeStyles(() => ({
	textAreaField: {
		background: '#f5f5f5',
		borderRadius: 10,
		padding: 10,
		marginBottom: 20,
		fontSize: 16
	}
}));

export default function PromotionForm(props) {
	const classes = useStyles(props);
	const {promotion, greeting} = useSelector(({auth}) => auth.user);
	const dispatch = useDispatch();
	const [initData, setInitData] = useState({promotion, greeting});
	const token = Auth.getToken();

	const updateInitData = () =>{
		axios.post('/api/user/updateProfile', initData, { headers: {"Authorization": "Bearer " + token}})
			.then(({data}) => {
				dispatch(userActions.setUserData(data.user));
				dispatch(Actions.showMessage({message: 'Update succeed!', variant: 'success'}));
			}).catch(() => {
				dispatch(Actions.showMessage({message: 'Update Failed!', variant: 'error'}));
			});

	}

	return (
    <>
      <FormControl className="flex">
        <InputLabel shrink htmlFor="promotion-input" className="mb-10 text-16 font-bold">
            Promotional Text
        </InputLabel>
        <Input
          classes={{
              root: classes.textAreaField,
          }}
          autoFocus
          id="promotion-input"
          rows="3"
          disableUnderline
          value={initData.promotion}
          onChange={(e) => setInitData({...initData, promotion: e.target.value})}
          fullWidth
          multiline
        />
      </FormControl>
      <FormControl className="flex">
        <InputLabel shrink htmlFor="greeting-input" className="mb-10 text-16 font-bold">
            Greeting
        </InputLabel>
        <Input
          classes={{
              root: classes.textAreaField,
          }}
          value={initData.greeting}
          id="greeting-input"
          rows="3"
          disableUnderline
          onChange={(e) => setInitData({...initData, greeting: e.target.value})}
          fullWidth
          multiline
        />
      </FormControl>
      <Button onClick={updateInitData} color="primary" variant="contained" className="rounded-full" size="large">
        Update
      </Button>
    </>
	);
}
