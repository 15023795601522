import React from 'react';
import PropTypes from 'prop-types';
import {VelocityComponent} from 'velocity-react';
import 'velocity-animate/velocity.ui';

const CoreAnimate = React.forwardRef(function CoreAnimate(props, ref) {
	const children = React.cloneElement(props.children, {
		style: {
			...props.children.style,
			visibility: 'hidden'
		}
	});
	return <VelocityComponent ref={ref} {...props}>{children}</VelocityComponent>;
});

CoreAnimate.propTypes = {
	children: PropTypes.element.isRequired
};

CoreAnimate.defaultProps = {
	animation          : 'transition.fadeIn',
	runOnMount         : true,
	targetQuerySelector: null,
	interruptBehavior  : 'stop',
	visibility         : 'visible',
	duration           : 300,
	delay              : 50,
	easing             : [0.4, 0.0, 0.2, 1],
	display            : null,
	setRef             : undefined
};

export default React.memo(CoreAnimate);
