import React from 'react';
import PropTypes from 'prop-types';
import {CoreLoading} from '@core';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */function CoreSuspense(props)
{
	return (
		<React.Suspense fallback={<CoreLoading {...props.loadingProps} />}>
			{props.children}
		</React.Suspense>
	);
}

CoreSuspense.propTypes = {
	loadingProps: PropTypes.object,
	children: PropTypes.element
};

CoreSuspense.defaultProps = {
	loadingProps: {
		delay: 0
	}
};

export default CoreSuspense;
