const isDev = process.env.NODE_ENV === 'development';
const dev = {
	REGION: "us-east-2",
	USER_POOL_ID: "us-east-2_YfBqFpwO2",
	USER_POOL_BASE_URI: "https://sso.backoffice.co",
	APP_CLIENT_ID: "3auqfaibqk00fgrlunchof5chp",
	CALLBACK_URI: "http://localhost:3000/auth",
	SIGN_OUT_URI: "http://localhost:3000/",
	TOKEN_SCOPES: [
		"openid",
		"email",
		"profile"
	]
}
const prod = {
	REGION: "us-east-2",
	USER_POOL_ID: "us-east-2_YfBqFpwO2",
	USER_POOL_BASE_URI: "https://sso.backoffice.co",
	APP_CLIENT_ID: "3auqfaibqk00fgrlunchof5chp",
	CALLBACK_URI: "https://message.backoffice.co/auth",
	SIGN_OUT_URI: "https://message.backoffice.co/",
	TOKEN_SCOPES: [
		"openid",
		"email",
		"profile"
	]
}
const awsConfig = isDev ? dev : prod;

export default awsConfig;